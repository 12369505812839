

export const onSubmit = (values, setSubmitting, props, setError, setTracking) => {
    setSubmitting(true);
    const formData = new FormData()
    Object.keys(values).forEach((key, i) => {
        if (["skills", "weaknesses", "strengths"].includes(key)) {
            values[key].forEach((value, j) => {
                formData.append(key + `[${j}]`, value)
            })
        } else if (["job_history", "education"].includes(key)) {
            values[key].forEach((value, k) => {
                Object.keys(value).forEach((ky, z) => {
                    formData.append(key + `[${k}][${ky}]`, value[ky])
                })
            })
        } else {
            formData.append(key, values[key])
        }
    });
    fetch(`${process.env.GATSBY_API_URL}/job-application`, {
        method: 'POST',
        body: formData,
        headers: {
            "Accept": "text/json",
            // 'Content-Type': 'application/json',
        }
    })
        .then((response) => { setSubmitting(false); return response.json(); })
        .then((result) => {
            if(typeof result.errors !== 'undefined') {
                setError([result.message]);
            }

            if(typeof result.data !== 'undefined' && typeof result.data.tracking_id !== 'undefined') {
                setTracking(result.data.tracking_id);
            }
            console.log('Success:', result);
            setSubmitting(false);
        })
        .catch((error) => {
            console.error('Error:', error);
            setSubmitting(false);
        });
};