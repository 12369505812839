import React from 'react'
import { Field, ErrorMessage, FieldArray } from "formik";
const Experience = ({ setFieldValue, values }) => {
    return (
        <section className="col-12 experience-section my-4">
            <h4>Your Job Experience <span className="text-danger">*</span></h4>
            <div className="row">
                <div className="form-group col-sm-6"  >
                    <label htmlFor="total_experience">Total Experience (Years) </label>
                    <Field id="total_experience" name="total_experience" value={values.total_experience ?? ""} type="number" className="form-control" />
                    <span className="text-danger " ><ErrorMessage name="total_experience" /></span>
                </div>
                <div className="form-group col-sm-6"  >
                    <label htmlFor="relevant_experience">Relevant Job Experience (Years)  <span className="text-danger">*</span></label>
                    <Field id="relevant_experience" name="relevant_experience" value={values.relevant_experience ?? ""} type="number" className="form-control" />
                    <span className="text-danger" ><ErrorMessage name="relevant_experience" /></span>
                </div>
            </div>
            <div className="col-sm-10 col-md-11 upper-label">
                <div className="row">
                    <div className="col-3">
                        <label htmlFor="Title">Job Title</label>
                    </div>
                    <div className="col-3">
                        <label htmlFor="Company">Organisation / Company</label>
                    </div>
                    <div className="col-2">
                        <label htmlFor="Year">Start</label>
                    </div>
                    <div className="col-2">
                        <label htmlFor="Year">End</label>
                    </div>
                    <div className="col-2">
                        <label htmlFor="Salary">Salary</label>
                    </div>
                </div>
            </div>
            <FieldArray name="job_history" >
                {({ insert, remove, push }) => (
                    <div className="hideLable">
                        {values?.job_history?.length > 0 &&
                            values.job_history.map((job_history, index) => (
                                <div className="row mb-4" key={index} >
                                    <div className="col-sm-10 col-md-11">
                                        <div className="row" >
                                            <div className="col-12 col-md-3 ">
                                                <label htmlFor={`job_history.${index}.title`}>Job Title</label>
                                                <Field
                                                    name={`job_history.${index}.title`}
                                                    type="text"
                                                    className="form-control"
                                                />
                                                <ErrorMessage
                                                    name={`job_history.${index}.title`}
                                                    component="div"
                                                    className="field-error text-danger"
                                                />
                                            </div>
                                            <div className="col-12 col-md-3 ">
                                                <label htmlFor={`job_history.${index}.company`}>Organisation / Company</label>
                                                <Field
                                                    name={`job_history.${index}.company`}
                                                    type="text"
                                                    className="form-control"
                                                />
                                                <ErrorMessage
                                                    name={`job_history.${index}.company`}
                                                    component="div"
                                                    className="field-error text-danger"
                                                />
                                            </div>
                                            <div className="col-12 col-md-2 ">
                                                <label htmlFor={`job_history.${index}.start`}>Start</label>
                                                <Field
                                                    name={`job_history.${index}.start`}
                                                    type="number"
                                                    min="1980" max="2099"
                                                    className="form-control"
                                                    placeholder="1990"
                                                />
                                                <ErrorMessage
                                                    name={`job_history.${index}.start`}
                                                    component="div"
                                                    className="field-error text-danger"
                                                />
                                            </div>
                                            <div className="col-12 col-md-2 ">
                                                <label htmlFor={`job_history.${index}.end`}>End</label>
                                                <Field
                                                    name={`job_history.${index}.end`}
                                                    type="number"
                                                    min="1980" max="2099"
                                                    className="form-control"
                                                    placeholder="1990"
                                                />
                                                <ErrorMessage
                                                    name={`job_history.${index}.end`}
                                                    component="div"
                                                    className="field-error text-danger"
                                                />
                                            </div>
                                            <div className="col-12 col-md-2  ">
                                                <label htmlFor={`job_history.${index}.salary`}>Salary</label>
                                                <Field
                                                    name={`job_history.${index}.salary`}
                                                    type="number"
                                                    min='0'
                                                    className="form-control"
                                                />
                                                <ErrorMessage
                                                    name={`job_history.${index}.salary`}
                                                    component="div"
                                                    className="field-error text-danger"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-2 col-md-1 pt-2 d-flex mobileButtons">
                                        {values?.job_history?.length > 1 && <button
                                            type="button"
                                            className="button"
                                            onClick={() => remove(index)}
                                        >
                                            <span className="d-md-block d-none">x</span>
                                            <span className="d-md-none d-block">Remove</span>
                                        </button>}

                                        <button
                                            type="button"
                                            className="button ms-2"
                                            onClick={() => push({ title: "", company: "", start: "", end: "", salary: "" })}
                                        >
                                            <span className="d-md-block d-none">+</span>
                                            <span className="d-md-none d-block">Add More</span>
                                        </button>
                                    </div>
                                </div>
                            ))}
                    </div>
                )}
            </FieldArray>
        </section>
    )
}

export default Experience