export const customStyles = {

    control: (base, state) => ({
        ...base, minHeight: "50px",
        backgroundColor: "#f5f5f5",
        boxShadow: state.isFocused ? `0 !important` : 'none', borderRadius: '0', fontSize: '14px',
        border: state.isFocused ? "1px solid #44ce6f" : "1px solid #eceaea",
        ':hover': {
            border: '1px solid #44ce6f',
            boxShadow: `0 !important`
        }
    }),
    option: (option, state) => ({ ...option, backgroundColor: state.isSelected ? '#44ce6f !important' : 'transparent', ":hover": { backgroundColor: '#51ab1d3b' } }),
    valueContainer: (valueContainer) => ({ ...valueContainer, flexWrap: 'nowrap', overflowX: 'auto', color: '#6e6e6e' }),
    multiValue: (multiValue) => ({ ...multiValue, flex: '0 1 auto', color: '#6e6e6e' }),
    singleValue: (value) => ({ ...value, color: '#6e6e6e' }),
    multiValueLabel: (valueLabel) => ({ ...valueLabel, color: '#6e6e6e' }),
    input: (input) => ({ ...input, border: '0 !important', boxShadow: "none !important", outline: "#000" }),
    menu: (menu) => ({ ...menu, backgroundColor: "#fff", zIndex: 9 })
}
