import React from 'react'
import { Field, ErrorMessage, FieldArray } from "formik";
import AdvanceSelect from "../Helpers/advanceselect"
import { salary, skills, cites, source } from '../Helpers/dropdowns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const About = ({ setFieldValue, values, jobs }) => {

    const dateFormat = (name, val) => {
        // console.log(val.toLocaleDateString('en-PK'));
        setFieldValue(name, val);
        setFieldValue(name + '_formatted', val.toLocaleDateString('en-PK'));
    }

    return (
        <section className="col-12 about-section my-4">
            <h4>Tell us about yourself</h4>

            <div className="row gy-4 mb-4">
                <div className="col-md-6"  >
                    <label htmlFor="job_id">What type of position would interest you the most? <span className="text-danger">*</span></label>
                    <AdvanceSelect name="job_id" value={values.job_id} setFieldValue={setFieldValue} options={jobs} />
                    <span className="text-danger" ><ErrorMessage name="job_id" /></span>
                </div>

                <div className="col-md-3"  >
                    <label htmlFor="exp_salary">Expected Salary <span className="text-danger">*</span></label>
                    <AdvanceSelect name="exp_salary" value={values.exp_salary} setFieldValue={setFieldValue} options={salary} />
                    <span className="text-danger" ><ErrorMessage name="exp_salary" /></span>
                </div>

                <div className="col-sm-3"  >
                    <label htmlFor="gender ">Gender <span className="text-danger">*</span></label>
                    <AdvanceSelect name="gender" value={values.gender}
                        options={[{ label: "Male", value: "male" }, { label: "Female", value: "female" }, { label: "Other", value: "other" }]}
                        setFieldValue={setFieldValue} lableValue="title" />
                    <span className="text-danger" ><ErrorMessage name="gender" /></span>
                </div>
            </div>
            {/*<small>It's an onsite job, not a remote job.</small>*/}
            <div className="row gy-4">
                <div className="col-sm-4"  >
                    <label htmlFor="hometown">Hometown <span className="text-danger">*</span></label>
                    <AdvanceSelect name="hometown" value={values.hometown} setFieldValue={setFieldValue} options={cites} />
                    <span className="text-danger" ><ErrorMessage name="hometown" /></span>
                </div>

                <div className="col-sm-4"  >
                    <label htmlFor="residence_city">Residence City <span className="text-danger">*</span></label>
                    <AdvanceSelect name="residence_city" value={values.residence_city} setFieldValue={setFieldValue} options={cites} />
                    <span className="text-danger" ><ErrorMessage name="residence_city" /></span>
                </div>

                <div className="col-sm-4"  >
                    <label htmlFor="dob ">DOB <span className="text-danger">*</span></label>
                    <DatePicker selected={values.dob ?? ""} id="dob" autoComplete='off' name="dob" showYearDropdown scrollableYearDropdown
                        className="form-control" placeholderText="Select your dob" onChange={(date) => dateFormat('dob', date)} showMonthDropdown
                        dateFormat="dd/MM/yyyy" yearDropdownItemNumber={50} maxDate={new Date()}
                        useShortMonthInDropdown dropdownMode="select" />
                    <span className="text-danger" ><ErrorMessage name="dob" /></span>
                </div>

            </div>



            <div className="form-group my-4"  >
                <label htmlFor="skills ">Please select some soft skills <span className="text-danger">*</span></label>
                <AdvanceSelect name="skills" value={values.skills} isMulti={true}
                    setFieldValue={setFieldValue} options={skills}
                />
                <span className="text-danger d-block" ><ErrorMessage name="skills" /></span>
                <small>Please select less than 5 skills, as the interviewer will question you about the selected skills.</small>
            </div>

            <div className="row">
                <FieldArray name="strengths">
                    {({ insert, remove, push }) => (
                        <div className="col-md-6">

                            <label htmlFor="strengths"> What are your key strengths? <span className="text-danger">*</span></label>
                            {values.strengths.length > 0 &&
                                values.strengths.map((strength, index) => (
                                    <div className="d-flex align-items-center mb-3" key={index} style={{ gap: 10 }}>
                                        <div className="w-100">
                                            <Field
                                                name={`strengths.${index}`}
                                                type="text"
                                                className="form-control "
                                            />
                                            <ErrorMessage
                                                name={`strengths.${index}`}
                                                component="div"
                                                className="field-error text-danger"
                                            />
                                        </div>
                                        {values.strengths.length > 1 && <button
                                            type="button"
                                            className="button"
                                            onClick={() => remove(index)}
                                        >
                                            x
                                        </button>}
                                        <button
                                            type="button"
                                            className="button"
                                            onClick={() => push('')}
                                        >
                                            +

                                        </button>
                                    </div>
                                ))}

                        </div>
                    )}
                </FieldArray>

                <FieldArray name="weaknesses">
                    {({ insert, remove, push }) => (
                        <div className="col-md-6">

                            <label htmlFor="weaknesses"> What are your key weaknesses? <span className="text-danger">*</span></label>
                            {values.weaknesses.length > 0 &&
                                values.weaknesses.map((weaknesse, index) => (
                                    <div className="d-flex align-items-center mb-3" key={index} style={{ gap: 10 }}>
                                        <div className="w-100">
                                            <Field
                                                name={`weaknesses.${index}`}
                                                type="text"
                                                className="form-control "
                                            />
                                            <ErrorMessage
                                                name={`weaknesses.${index}`}
                                                component="div"
                                                className="field-error text-danger"
                                            />
                                        </div>
                                        {values.weaknesses.length > 1 && <button
                                            type="button"
                                            className="button"
                                            onClick={() => remove(index)}
                                        >
                                            x
                                        </button>}
                                        <button
                                            type="button"
                                            className="button"
                                            onClick={() => push('')}
                                        >
                                            +
                                        </button>
                                    </div>
                                ))}

                        </div>
                    )}
                </FieldArray>
            </div>

            <div className="col-lg-6 form-group"  >
                <label htmlFor="source">Where did you hear about us <span className="text-danger">*</span></label>
                <AdvanceSelect name="source" value={values.source} setFieldValue={setFieldValue} options={source} />
                <span className="text-danger" ><ErrorMessage name="source" /></span>
            </div>

            <div className="form-group"  >
                <label htmlFor="why_us">Why Karigar? <span className="text-danger">*</span></label>
                <Field as="textarea" rows="5" id="why_us" name="why_us" type="text" value={values.why_us ?? ""}
                    placeholder="Enter some words..." className="form-control" autoComplete='off'
                />
                <span className="text-danger" ><ErrorMessage name="why_us" /></span>
            </div>
        </section>
    )
}

export default About