import React from 'react'
import { Field, ErrorMessage, FieldArray } from "formik";
import AdvanceSelect from "../Helpers/advanceselect"
import { lastEducation } from '../Helpers/dropdowns';
const Education = ({ setFieldValue, values }) => {
    return (
        <section className="col-12 eduction-section my-4">
            <h4>Education <span className="text-danger">*</span></h4>
            <div className="form-group"  >
                <label htmlFor="last_education ">Your Last Degree <span className="text-danger">*</span></label>
                <AdvanceSelect name="last_education" value={values.last_education} setFieldValue={setFieldValue} options={lastEducation} />
                <span className="text-danger d-block" ><ErrorMessage name="last_education" /></span>
            </div>
            <div className="col-sm-10 col-md-11 upper-label">
                <div className="row">
                    <div className="col-3">
                        <label htmlFor="Qualification">Qualification/Certification</label>
                    </div>
                    <div className="col-3">
                        <label htmlFor="Institute">Institute</label>
                    </div>
                    <div className="col-3">
                        <label htmlFor="Percentage">Percentage/GPA</label>
                    </div>
                    <div className="col-3">
                        <label htmlFor="Year">Year Passed</label>
                    </div>
                </div>
            </div>
            <FieldArray name="education" >
                {({ insert, remove, push }) => (
                    <div className="hideLable">
                        {values?.education?.length > 0 &&
                            values.education.map((revenue, index) => (
                                <div className="row mb-4" key={index} >
                                    <div className="col-sm-10 col-md-11">
                                        <div className="row" >
                                            <div className="col-sm-3 ">
                                                <label htmlFor={`education.${index}.qualification`}>Qualification/Certification</label>
                                                <Field
                                                    name={`education.${index}.qualification`}
                                                    type="text"
                                                    className="form-control"
                                                />
                                                <ErrorMessage
                                                    name={`education.${index}.qualification`}
                                                    component="div"
                                                    className="field-error text-danger"
                                                />
                                            </div>
                                            <div className="col-sm-3 ">
                                                <label htmlFor={`education.${index}.institute`}>Institute</label>
                                                <Field
                                                    name={`education.${index}.institute`}
                                                    type="text"
                                                    className="form-control"
                                                />
                                                <ErrorMessage
                                                    name={`education.${index}.institute`}
                                                    component="div"
                                                    className="field-error text-danger"
                                                />
                                            </div>
                                            <div className="col-sm-3 ">
                                                <label htmlFor={`education.${index}.gpa`}>Percentage/GPA</label>
                                                <Field
                                                    name={`education.${index}.gpa`}
                                                    type="number"
                                                    step=".1"
                                                    min='0'
                                                    className="form-control"
                                                />
                                                <ErrorMessage
                                                    name={`education.${index}.gpa`}
                                                    component="div"
                                                    className="field-error text-danger"
                                                />
                                            </div>
                                            <div className="col-sm-3  ">
                                                <label htmlFor={`education.${index}.year`}>Year Passed</label>
                                                <Field
                                                    name={`education.${index}.year`}
                                                    type="number"
                                                    min="1995" max="2030"
                                                    className="form-control"
                                                    placeholder="2010"
                                                />
                                                <ErrorMessage
                                                    name={`education.${index}.year`}
                                                    component="div"
                                                    className="field-error text-danger"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-2 col-md-1 pt-2 d-flex mobileButtons">
                                        {values?.education?.length > 1 && <button
                                            type="button"
                                            className="button"
                                            onClick={() => remove(index)}
                                        >
                                            <span className="d-md-block d-none">x</span>
                                            <span className="d-md-none d-block">Remove</span>
                                        </button>}

                                        <button
                                            type="button"
                                            className="button ms-2"
                                            onClick={() => push({ qualification: "", institute: "", gpa: "", year: "" })}
                                        >   <span className="d-md-block d-none">+</span>
                                            <span className="d-md-none d-block">Add More</span>
                                        </button>
                                    </div>
                                </div>
                            ))}
                    </div>
                )}
            </FieldArray>
            <div className="col-12">
                <small>Please list maximum details for your education and trainings.</small>
            </div>
        </section>

    )
}

export default Education