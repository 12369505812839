import React from 'react'
import {  Field,  FieldArray } from "formik";
const FileAttachment = ({setFieldValue, values}) => {
    return (
        <section className="col-12 social-media-section my-4">
            <h4>CV / Resume</h4>
            <div className="row g-4">
                <FieldArray name="file_attachments">
                    <div className="col-sm-6">
                        {/*<label htmlFor="cv">*/}
                        {/*    CV / Resume*/}
                        {/*</label>*/}
                        <Field
                            id="cv"
                            name="cv"
                            type="file"
                            className="form-control"
                            onChange={(event) => {
                                // console.log(event.currentTarget.files[0]);
                                // setFieldValue("cv", event.currentTarget.files[0].name);
                                setFieldValue("cv_file", event.currentTarget.files[0]);
                            }}
                        />

                    </div>
                </FieldArray>
            </div>
        </section>
    )
}

export default FileAttachment