import React, { useState, useCallback, useEffect } from 'react';
import { onSubmit } from "./Helpers/functions"
import { initialVals } from "./Helpers/intialvalues"
import { validationSchema } from "./Helpers/validationSchema"
import { Formik, Field, ErrorMessage } from "formik";
import "./jobform.css"
import About from './Sections/About';
import Education from './Sections/Education';
import Experience from './Sections/Experience';
import SocialMedia from './Sections/SocialMedia';
import FileAttachment from "./Sections/FileAttachment";
import Success from "./Sections/Success";
import NoJobs from "./Sections/NoJobs";
const JobForm = (props) => {
    const [error, setError] = useState([]);
    const [tracking, setTracking] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);

    // console.log(jobId);
    const loadJobs = useCallback(async () => {
        const response = await fetch(`${process.env.GATSBY_API_URL}/jobs-list`);
        const resResult = await response.json();
        if (typeof resResult.meta !== 'undefined' && resResult.meta.total > 0) {
            setJobs(resResult.data);
        }

        setLoading(false);
    }, []);

    useEffect(() => {
        loadJobs();
    }, [loadJobs]);

    if (loading) {
        return (
            <div className="d-flex justify-content-center m-5">
                <div className="spinner-grow text-success" style={{ width: '5rem', height: '5rem' }} role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    if (tracking) {
        return (<Success tracking={tracking} />)
    }

    if (jobs.length === 0) {
        return (<NoJobs />)
    }

    return (
        <div className="contact-area ptb-80">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-12">
                        {error.length > 0 ? (
                            <div className={"alert alert-danger"}>
                                <ul>
                                    {error.map((error) => (
                                        <li>{error}</li>
                                    ))}
                                </ul>
                            </div >
                        ) : (
                            ""
                        )}

                        <Formik
                            validationSchema={validationSchema()}
                            initialValues={initialVals}
                            onSubmit={(values) => onSubmit(values, setSubmitting, props, setError, setTracking)}
                            enableReinitialize={true}
                        >
                            {({ values, errors, touched, setFieldValue, handleSubmit, }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="mb-3 row" style={{ gap: '20px 0px', girdGap: '20px 0px' }}  >

                                        <div className="col-md-6"  >
                                            <label htmlFor="first_name">First Name <span className="text-danger">*</span></label>
                                            <Field id="first_name" name="first_name" type="text" value={values.first_name ?? ""}
                                                placeholder="Enter first name" className="form-control" autoComplete='off'
                                            />
                                            <span className="text-danger" ><ErrorMessage name="first_name" /></span>
                                        </div>

                                        <div className="col-md-6"  >
                                            <label htmlFor="last_name">Last Name <span className="text-danger">*</span></label>
                                            <Field id="last_name" name="last_name" type="text" value={values.last_name ?? ""}
                                                placeholder="Enter last name" className="form-control" autoComplete='off'
                                            />
                                            <span className="text-danger" ><ErrorMessage name="last_name" /></span>
                                        </div>

                                        <div className="col-md-6"  >
                                            <label htmlFor="email">Email <span className="text-danger">*</span></label>
                                            <Field id="email" name="email" type="text" value={values.email ?? ""}
                                                placeholder="Enter your email" className="form-control" autoComplete='off'
                                            />
                                            {!errors.email && <small>We use email for correspondence, so only provide your active email address</small>}
                                            <span className="text-danger d-block" ><ErrorMessage name="email" /></span>
                                        </div>

                                        <div className="col-md-6"  >
                                            <label htmlFor="phone">Phone <span className="text-danger">*</span></label>
                                            <Field id="phone" className="form-control" placeholder="Enter phone number" name="phone" value={values.phone ?? ""} />
                                            <span className="text-danger" ><ErrorMessage name="phone" /></span>
                                        </div>

                                        {/* about section */}
                                        <About values={values} setFieldValue={setFieldValue} jobs={jobs} />

                                        {/* education section */}
                                        <Education values={values} setFieldValue={setFieldValue} />

                                        {/* experience section */}
                                        <Experience values={values} setFieldValue={setFieldValue} />

                                        <FileAttachment values={values} setFieldValue={setFieldValue} />

                                        {/* Social Media section */}
                                        <SocialMedia values={values} />

                                        <div className="col-12 text-md-start text-center">
                                            <button
                                                className='btn btn-primary'
                                                type="submit"
                                                disabled={submitting}
                                            >
                                                {submitting ? 'Submiting...' :
                                                    "Save"
                                                }
                                            </button>
                                        </div>
                                    </div>

                                    {false && (
                                        <div className={'row'}>
                                            <div className={'col-12'}>
                                                <code>
                                                    <pre>Values: {JSON.stringify(values, null, 2)}</pre>
                                                </code>
                                            </div>
                                            <div className={'col-12'}>
                                                <pre>Errors: {JSON.stringify(errors, null, 2)}</pre>
                                            </div>
                                            <div className={'col-12'}>
                                                <pre>Touched: {JSON.stringify(touched, null, 2)}</pre>
                                            </div>
                                        </div>
                                    )}
                                </form>
                            )}
                        </Formik>

                    </div>
                </div >
            </div >
        </div >
    )
}

export default JobForm;