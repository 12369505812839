import React from 'react'
const Success = ({tracking}) => {
    return (
        <div className="contact-area ptb-80">
            <div className="container">
                <div className="row">
                    <div className="alert alert-warning text-center">
                        Thank you for showing interest in joining the our team. Unfortunately we don't have any available job oppurtunities at the moment.<br />
                        Please try again later or for any other information contact our team at <a rel="noreferrer"  target="_blank" href="mailto:jobs@karigar.pk">jobs@karigar.pk</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Success