export const jobs = [
    { value: 1, label: "Backend Developer" },
    { value: 2, label: "Laravel Developer" },
    { value: 3, label: "Mobile Apps Developer" },
    { value: 4, label: "Blockchain / Crypto Currency Developer" },
    { value: 5, label: "DevOps" },
    { value: 6, label: "Graphic Designer/ Web Designer" },
    { value: 7, label: "Linux System Administrator" },
    { value: 8, label: "Marketing Strategist" },
    { value: 9, label: "SQA Analyst" },
    { value: 10, label: "QA Engineer" },
    { value: 11, label: "SEO Analyst" },
    { value: 12, label: "IT Sales Captain" },
    { value: 13, label: "Data Entry Operator" },
    { value: 14, label: "Project Manager" },
    { value: 15, label: "Office Administrator" },
    { value: 16, label: "HR Manager" },
    { value: 17, label: "Customer Success Manager" },
    { value: 18, label: "WordPress Developer" },
    { value: 19, label: "Other" },
]

export const salary = [
    { value: "10K - 30K", label: "10K - 30K" },
    { value: "30K - 50K", label: "30K - 50K" },
    { value: "51K - 80K", label: "51K - 80K" },
    { value: "131K - 200K", label: "131K - 200K" },
    { value: "200K - 300K", label: "200K - 300K" },
    { value: "300K+", label: "300K+" },
]

export const skills = [
    { value: 'pact Framework', label: 'pact Framework' },
    { value: 'Express.js', label: 'Express.js' },
    { value: 'NET for Web', label: 'NET for Web' },
    { value: 'NET Framework', label: 'NET Framework' },
    { value: 'NET Remoting', label: 'NET Remoting' },
    { value: 'ShoppingCart', label: 'ShoppingCart' },
    { value: 'A2Billing', label: 'A2Billing' },
    { value: 'Ab Initio', label: 'Ab Initio' },
    { value: 'AbleCommerce', label: 'AbleCommerce' },
    { value: 'Abstract Window Toolkit (AWT)', label: 'Abstract Window Toolkit (AWT)' },
    { value: 'ActionScript', label: 'ActionScript' },
    { value: 'ActiveX', label: 'ActiveX' },
    { value: 'ADO.NET', label: 'ADO.NET' },
    { value: 'ADO.NET Entity Framework', label: 'ADO.NET Entity Framework' },
    { value: 'AJAX', label: 'AJAX' },
    { value: 'Algorithm Development', label: 'Algorithm Development' },
    { value: 'Algorithms', label: 'Algorithms' },
    { value: 'Amazon Appstore', label: 'Amazon Appstore' },
    { value: 'Amazon EC2', label: 'Amazon EC2' },
    { value: 'Amazon Relational database service', label: 'Amazon Relational database service' },
    { value: 'Amazon S3', label: 'Amazon S3' },
    { value: 'Amazon Web Services', label: 'Amazon Web Services' },
    { value: 'Antispam and Antivirus', label: 'Antispam and Antivirus' },
    { value: 'Apache Administration', label: 'Apache Administration' },
    { value: 'Apache Avro', label: 'Apache Avro' },
    { value: 'Apache Tiles', label: 'Apache Tiles' },
    { value: 'API Development', label: 'API Development' },
    { value: 'API Documentation', label: 'API Documentation' },
    { value: 'ASP.NET', label: 'ASP.NET' },
    { value: 'ASP.NET MVC', label: 'ASP.NET MVC' },
    { value: 'Atom', label: 'Atom' },
    { value: 'Authorize.Net Development', label: 'Authorize.Net Development' },
    { value: 'Basecamp', label: 'Basecamp' },
    { value: 'Bash', label: 'Bash' },
    { value: 'Big Data', label: 'Big Data' },
    { value: 'Blogs', label: 'Blogs' },
    { value: 'C#', label: 'C#' },
    { value: 'C++', label: 'C++' },
    { value: 'Cache Management', label: 'Cache Management' },
    { value: 'CakePHP', label: 'CakePHP' },
    { value: 'Chrome Extension', label: 'Chrome Extension' },
    { value: 'CodeIgniter', label: 'CodeIgniter' },
    { value: 'Compiler', label: 'Compiler' },
    { value: 'cPanel', label: 'cPanel' },
    { value: 'CSS', label: 'CSS' },
    { value: 'CSS3', label: 'CSS3' },
    { value: 'cURL', label: 'cURL' },
    { value: 'Custom CMS', label: 'Custom CMS' },
    { value: 'Data Structures', label: 'Data Structures' },
    { value: 'Database Management', label: 'Database Management' },
    { value: 'Database Programming', label: 'Database Programming' },
    { value: 'Database Testing', label: 'Database Testing' },
    { value: 'DBMS', label: 'DBMS' },
    { value: 'Debugging', label: 'Debugging' },
    { value: 'DHTML', label: 'DHTML' },
    { value: 'Django', label: 'Django' },
    { value: 'Domain Migration', label: 'Domain Migration' },
    { value: 'Drop Shipping', label: 'Drop Shipping' },
    { value: 'Drupal', label: 'Drupal' },
    { value: 'eBay API', label: 'eBay API' },
    { value: 'eBay Web Services', label: 'eBay Web Services' },
    { value: 'Ecommerce', label: 'Ecommerce' },
    { value: 'Firewall', label: 'Firewall' },
    { value: 'Flask', label: 'Flask' },
    { value: 'Flowcharts', label: 'Flowcharts' },
    { value: 'Forums', label: 'Forums' },
    { value: 'FTP', label: 'FTP' },
    { value: 'Git', label: 'Git' },
    { value: 'GitHub', label: 'GitHub' },
    { value: 'Google AdSense API', label: 'Google AdSense API' },
    { value: 'Google AdWords Development', label: 'Google AdWords Development' },
    { value: 'Google Map Maker', label: 'Google Map Maker' },
    { value: 'Google Maps', label: 'Google Maps' },
    { value: 'HTML', label: 'HTML' },
    { value: 'HTML5', label: 'HTML5' },
    { value: 'Javascript', label: 'Javascript' },
    { value: 'JDBC', label: 'JDBC' },
    { value: 'JDBC Programming', label: 'JDBC Programming' },
    { value: 'Jellybean', label: 'Jellybean' },
    { value: 'JIRA', label: 'JIRA' },
    { value: 'Joomla!', label: 'Joomla!' },
    { value: 'jQuery', label: 'jQuery' },
    { value: 'JSON', label: 'JSON' },
    { value: 'JSP', label: 'JSP' },
    { value: 'Laravel', label: 'Laravel' },
    { value: 'LINQ', label: 'LINQ' },
    { value: 'Linux', label: 'Linux' },
    { value: 'Microsoft Certified Information Technology Professional (MCITP)', label: 'Microsoft Certified Information Technology Professional (MCITP)' },
    { value: 'Microsoft Dynamics Administration', label: 'Microsoft Dynamics Administration' },
    { value: 'Microsoft Dynamics CRM', label: 'Microsoft Dynamics CRM' },
    { value: 'Microsoft Dynamics ERP', label: 'Microsoft Dynamics ERP' },
    { value: 'Microsoft Server', label: 'Microsoft Server' },
    { value: 'Microsoft Visual C++', label: 'Microsoft Visual C++' },
    { value: 'Microsoft Visual Studio', label: 'Microsoft Visual Studio' },
    { value: 'MySQL Administration', label: 'MySQL Administration' },
    { value: 'MySQL Programming', label: 'MySQL Programming' },
    { value: 'NetBeans', label: 'NetBeans' },
    { value: 'PHPStorm', label: 'PHPStorm' },
    { value: 'Visual Code', label: 'Visual Code' },
    { value: 'Nginx', label: 'Nginx' },
    { value: 'Node.JS', label: 'Node.JS' },
    { value: 'Mongo', label: 'Mongo' },
    { value: 'NoSQL', label: 'NoSQL' },
    { value: 'Object Oriented PHP', label: 'Object Oriented PHP' },
    { value: 'OLAP', label: 'OLAP' },
    { value: 'On-Page Optimization', label: 'On-Page Optimization' },
    { value: 'OOP', label: 'OOP' },
    { value: 'OpenCart', label: 'OpenCart' },
    { value: 'Oracle 10g Administration', label: 'Oracle 10g Administration' },
    { value: 'Oracle Administration', label: 'Oracle Administration' },
    { value: 'Oracle Database', label: 'Oracle Database' },
    { value: 'Oracle Database Administration', label: 'Oracle Database Administration' },
    { value: 'PHP', label: 'PHP' },
    { value: 'phpMyAdmin', label: 'phpMyAdmin' },
    { value: 'Python', label: 'Python' },
    { value: 'Python Numpy', label: 'Python Numpy' },
    { value: 'Python SciPy', label: 'Python SciPy' },
    { value: 'Raspberry Pi', label: 'Raspberry Pi' },
    { value: 'Regular Expressions', label: 'Regular Expressions' },
    { value: 'Relational Databases', label: 'Relational Databases' },
    { value: 'REST', label: 'REST' },
    { value: 'Ruby on Rails', label: 'Ruby on Rails' },
    { value: 'Shopify', label: 'Shopify' },
    { value: 'SMTP', label: 'SMTP' },
    { value: 'SOAP', label: 'SOAP' },
    { value: 'SQL', label: 'SQL' },
    { value: 'SQL Azure', label: 'SQL Azure' },
    { value: 'SQL Programming', label: 'SQL Programming' },
    { value: 'SQL Server Integration Services (SSIS)', label: 'SQL Server Integration Services (SSIS)' },
    { value: 'SQLite Administration', label: 'SQLite Administration' },
    { value: 'SSH', label: 'SSH' },
    { value: 'SSL', label: 'SSL' },
    { value: 'Ubuntu', label: 'Ubuntu' },
    { value: 'VB.NET', label: 'VB.NET' },
    { value: 'Web Host Manager', label: 'Web Host Manager' },
    { value: 'Web Hosting', label: 'Web Hosting' },
    { value: 'Web Programming', label: 'Web Programming' },
    { value: 'Web scraping', label: 'Web scraping' },
    { value: 'Web Services', label: 'Web Services' },
    { value: 'Web Services Development', label: 'Web Services Development' },
    { value: 'Wix', label: 'Wix' },
    { value: 'WordPress', label: 'WordPress' },
    { value: 'WordPress e-Commerce', label: 'WordPress e-Commerce' },
    { value: 'XAML', label: 'XAML' },
    { value: 'XAMPP', label: 'XAMPP' },
    { value: 'XML', label: 'XML' },
    { value: 'Zen Cart', label: 'Zen Cart' },
    { value: 'Zend', label: 'Zend' },
]

export const lastEducation = [
    { value: "doctorate", label: "Doctorate" },
    { value: "master", label: "Master" },
    { value: "bachelor", label: "Bachelor" },
    { value: "associateDegree", label: "Associate Degrees" },
    { value: "hssc", label: "Higher Secondary School Certificate (HSSC)" },
    { value: "ssc", label: "Secondary School Certificate (SSC)" },
]

export const cites = [
    { value: "sialkot", label: "Sialkot" },
    { value: "gujranwala", label: "Gujranwala" },
    { value: "lahore", label: "Lahore" },
    { value: "gujrat", label: "Gujrat" },
    { value: "wazirabad", label: "Wazirabad" },
    { value: "sambarial", label: "Sambarial" },
    { value: "daska", label: "Daska" },
    { value: "pasroor", label: "Pasroor" },
]

export const experience = [
    { value: "Fresher", label: 'Fresher' },
    { value: "Completed Internship", label: 'Completed Internship' },
    { value: "Less than 1 year", label: 'Less than 1 year' },
    { value: "More than 1 years", label: 'More than 1 years' },
    { value: "More than 3 years", label: 'More than 3 years' },
    { value: "More than 5 years", label: 'More than 5 years' }
]

export const source = [
    { value: "google", label: "Google" },
    { value: "facebook", label: "Facebook" },
    { value: "twitter", label: "Twitter" },
    { value: "instagram", label: "Instagram" },
    { value: "linkedin", label: "Linkedin" },
    { value: "member", label: "Karigar's Member" },
    { value: "university", label: "University Portal" },
    { value: "other", label: "Other" },
]