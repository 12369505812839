import React from 'react'
import {  Field,  FieldArray } from "formik";
const SocialMedia = ({values}) => {
    return (
        <section className="col-12 social-media-section my-4">
            <h4>Social Media - Optional</h4>
            <div className="row g-4">
                <FieldArray name="social_media">
                    {({ insert, remove, push }) => (
                        <>
                            {["LinkedIn", "Twitter", "Facebook", "Instagram"].map((s_item, index) => (
                                    <div className="col-sm-6" key={index}>
                                        <label htmlFor={`social_media.${index}`}>
                                            {/*{index === 0 ? "LinkedIn" : index === 1 ? "Twitter" : index === 2 ? "Facebook" : "Instagram"}*/}
                                            {s_item}
                                        </label>
                                        <Field
                                            id={`social_media.${index}`}
                                            name={`social_media.${index}`}
                                            type="text"
                                            className="form-control "
                                        />

                                    </div>
                                ))}

                        </>
                    )}
                </FieldArray>
            </div>
        </section>
    )
}

export default SocialMedia