import React from 'react'
const Success = ({tracking}) => {
    return (
        <div className="contact-area ptb-80">
            <div className="container">
                <div className="row align-items-center">
                    <div className="alert alert-success">
                        Thank you for showing interest in joining the our team. Please take note of your tracking ID for all future correspondence.<br />
                        Your tracking ID: <strong>{tracking}</strong>
                        <br />
                        <br />
                        <h6>What's Next?</h6>
                        <ul>
                            <li>Our HR team will review your application. You will receive an email with outcome of that review. This usually takes a week.</li>
                            <li>If your application is shortlisted during the first phase, our team will also contact you on provided phone number.</li>
                            <li>After completing the basic screening process on the call, HR team will arrange your on-site interview.</li>
                            <li>The time and location of the on-site interview will be communicate to you via email.</li>
                            <li>After completing the interview process, you will able to get an offer or rejection email within next few days.</li>
                        </ul>

                        <strong>Please feel free to reach on our office numbers for any inquiry during this process.</strong>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Success