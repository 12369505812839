import React, { useState } from 'react'
import Select from "react-select";
import { customStyles } from "./selectstyle"
const AdvanceSelect = ({ callback, setFieldValue, name, isMulti, value, options, }) => {

    const [values] = useState(options ?? [])


    const selectValue = (e) => {
        console.log(e)
        if (isMulti) {
            const values = e.map((val => val.value))
            setFieldValue(name, values)
            callback && callback(values)
        } else {
            setFieldValue(name, e.value)
            callback && callback(e.value)
        }
    }

    return (
        <Select
            id={name}
            isMulti={isMulti}
            key={values} styles={customStyles} options={values}
            onChange={selectValue} defaultValue={values && values.filter(
                (val) => val.value === value
            )} />
    )
}

export default AdvanceSelect