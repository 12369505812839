export const initialVals = {
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  exp_salary: "",
  source: "",
  skills: [],
  strengths: [""],
  weaknesses: [""],
  why_us: "",
  gender: "",
  last_education: "",
  education: [{ qualification: "", institute: "", gpa: "", year: "" }],
  total_experience: null,
  relevant_experience: null,
  job_history: [{ title: "", company: "", start: "", end: "", salary: "" }],
  social_media: ["", "", "", ""],
  dob: "",
  hometown: "",
  residence: "",
  job_id: null
};