import * as yup from "yup";
export const validationSchema = (id) => {
    return yup.object().shape({
        first_name: yup.string().required("Field is required!"),
        last_name: yup.string().required("Field is required!"),
        phone: yup.string('Provide a valid number').required("Field is required!").matches(
            (/^\+?[0-9]*$/),
            "Phone number is not valid"
        ).min(11),
        email: yup.string().email("Invalid email").required("Field is required!"),
        exp_salary: yup.string().required("Field is required!"),
        source: yup.string().required("Field is required!"),
        skills: yup.array().min(1, "skills field must have at least 1 skill").max(5, "skills field must have less than or equal to 5 skills").required("Field is required!"),
        strengths: yup.array().of(yup.string().required("Field is required!")),
        weaknesses: yup.array().of(yup.string().required("Field is required!")),
        why_us: yup.string().required("Field is required!"),
        gender: yup.string().required("Field is required!"),
        last_education: yup.string().required("Field is required!"),
        education: yup.array().of(
            yup.object().shape({
                qualification: yup.string().required("Qualification is required").nullable(),
                institute: yup.string().required("Institute is required").nullable(),
                gpa: yup.string().required("Percentage/GPA is required").nullable(),
                year: yup.string().required("passing year is required").nullable()
            })
        ),
        total_experience: yup.number().min(0, 'must be greater than to 0').positive("must be a positive number").required("Field is required!").nullable(),
        relevant_experience: yup.number().min(0, 'must be greater than to 0').positive("must be a positive number").required("Field is required!").nullable(),
        job_id: yup.number().required("Field is required!").nullable(),
        job_history: yup.array().of(
            yup.object().shape({
                title: yup.string().nullable().required("Title is required!"),
                company: yup.string().nullable().required("Company is required!"),
                start: yup.string().nullable().required("Start date is required!"),
                end: yup.string().nullable().required("End date is required!"),
                salary: yup.string().nullable().required("Salary is required!")
            })
        ),
        dob: yup.date().required('DOB is required').nullable(),
        dob_formatted: yup.string().required('DOB is required').nullable(),
        hometown: yup.string().required('Hometown is required').nullable(),
        residence_city: yup.string().required('Residence is required').nullable(),
    });
};